import React from 'react'
import "./CategoriesSlider.css"
import { ProductsCategory } from '../../../../components'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
const CategoriesSlider = ({categories,url}) => {

  return (
    <div className="categories-slider">
      <Splide
        options={{
          gap: "15px",
          perPage: 4,
          fixedWidth: "215px",
          pagination: false,
          arrows: false,
          breakpoints: {
            1250: {
              perPage: 4,
            },
            992: {
              perPage: 3,
            },
            768: {
              perPage: 2,
            },
            576: {
              perPage: 1,
              gap: "10",
              focus: 'center',
            }
          }
        }}
      >       
           {categories.map((category,index) => (
         <SplideSlide>
         <ProductsCategory
           title={category.name}
           image={
            category.image
              ? url + category.image
              : require("../../../../asset/images/Categories/category3.png")
          }
           color= {index%2==0?"#bca186": "#101728"} 
         />
         </SplideSlide>
          ))} 
      </Splide>
    </div>
  )
}

export default CategoriesSlider
