import React from 'react'
import "./ShoppingCartPage.css"
import { ShoppingCard, CardSummary } from '../../components/index'
import { Container, Row } from 'react-bootstrap';
import { Title, Loading } from "../../components";
import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import {
    Dialog,
    Button,
    DialogContentText,
    DialogActions,
    DialogTitle,
    DialogContent
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export const ShoppingCartPage = ({ url, apiMethod }) => {

    const [CartData, setCartData] = useState([]);
    const [SummeryData, setSummeryData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [itemCodeToModified, setItemCode] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const handleClickOpen = (item_code) => {
        setOpen(true);
        setItemCode(item_code);
    };

    const handleClose = (val) => {
        setOpen(false);
        if (val === "Yes" && itemCodeToModified) {
            DeleteFromCart(itemCodeToModified);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    // useEffect(() => {
    //     setLoading(false);
    //     console.log(CartData)
    // }, [CartData]);

    const fetchData = async () => {
        setLoading(true);
        const apiURL = `${url}${apiMethod}.shopping_cart?with_extra_information=true`;
        try {
            const headers = {
                "Authorization": `token ${Cookies.get("token")}`
            };
            const response = await axios.get(apiURL, { headers });
            const data = response?.data?.result?.items;
            const summeryData = response?.data?.result;
            setCartData(data);
            setSummeryData(summeryData)


        } catch (error) {
            setLoading(false);
            console.log('Error fetching shopping cart:', error);
        }
        setLoading(false);
    };


    const DeleteFromCart = async (item_code) => {
        const apiURL = `${url}${apiMethod}.update_cart`;
        try {
            const headers = {
                "Authorization": `token ${Cookies.get("token")}`
            };

            const response = await axios.post(apiURL,
                {
                    "item_code": item_code,
                    "qty": 0,
                }
                , { headers });
            if (response.status === 200) {
                fetchData();
            }

        } catch (error) {
            toast.error("Sorry, can't delete product from cart", {
                position: 'bottom-right',
            });
        }
    };
    const handleEdit = (item, name) => {
        setItemCode(item.item_code);

    };


    const EditCart = async (item, qty) => {
        setLoading(true);
        const apiURL = `${url}${apiMethod}.update_cart`;
        try {
            const headers = {
                "Authorization": `token ${Cookies.get("token")}`
            };

            const response = await axios.post(apiURL,
                {
                    "item_code": item.item_code,
                    "qty": qty,
                    "additional_notes": "",
                    "with_items": false,
                }
                , { headers });
            if (response.status == 200) {
                fetchData();
            }
            else {
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            toast.error("Sorry, can't update this product", {
                position: 'bottom-right',
            });
            console.log('Error in update product:', error);
        }
    };

    return (
        <div>
            {loading == true ?
                (
                    <Loading></Loading>
                )
                :
                <div className='shopping-page'>
                    <Container>
                        <Title title={"Shopping Cart"} />
                        <Row className='shopping-cart-row' >
                            <div className='col-md-12 col-lg-8 col-12 col-sm-12' style={{ paddingLeft: "unset" }}>

                                {loading == true ? (
                                    <CircularProgress
                                        size={24}
                                    />
                                ) :
                                    <div>
                                        {CartData.length === 0 ? (
                                            <center>  <p>Cart is empty</p> </center>
                                        ) : (
                                            <div >
                                                {CartData.map((item, index) => (
                                                    <ShoppingCard
                                                        title={item.web_item_name}
                                                        webItemId={item.web_item_id}
                                                        qty={item.qty}
                                                        amount={item.amount}
                                                        currency={SummeryData.currency}
                                                        image={url + item.website_image}
                                                        brand={item.brand}
                                                        onDelete={() => handleClickOpen(item.item_code)}
                                                        onEdit={() => handleEdit(item)}
                                                        onChangeQty={(qty) => EditCart(item, qty)}
                                                        description={item.description}
                                                        attributes={item.attributes}
                                                        isVarient={item.is_variant}
                                                        parentWebItemName={item.parent_web_item_name}
                                                        parentWebItemId={item.parent_web_item_id}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                }

                            </div>
                            <div className='col-md-12 col-lg-4 col-12 col-sm-12' style={{ paddingLeft: "unset" }}>
                                <CardSummary subTotal={SummeryData.total} taxes={SummeryData.total_taxes_and_charges} currency={SummeryData.currency}
                                    grandTotal={SummeryData.grand_total}
                                />
                            </div>
                        </Row>
                        <React.Fragment>
                            <Dialog
                                open={open}
                                onClose={() => handleClose('No')}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle style={{ fontFamily: 'Quicksand600' }} id="alert-dialog-title">
                                    {"Delete Product"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText style={{ fontFamily: 'Quicksand500' }} id="alert-dialog-description">
                                        Do you want to delete this product from your cart?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ fontFamily: 'Quicksand600', color: 'red' }} onClick={() => handleClose('No')}>No</Button>
                                    <Button style={{ fontFamily: 'Quicksand600', color: 'var(--main-color)' }} onClick={() => handleClose('Yes')} autoFocus>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                        <ToastContainer position="bottom-right" hideProgressBar="true" type="error" />

                    </Container>


                </div>
            }
        </div>

    )
}


export default ShoppingCartPage;