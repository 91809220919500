import "./CheckEmail.css"
import React from 'react'
import { ReactComponent as EmailSvg } from '../../../../asset/images/svg/email.svg';
 
const CheckEmail = ({RegisterEmail }) => {


  return (

    <div className='verification-container'>
      <div className="back-img">

        <div className="check-content">
        <div className="email-div">
            <EmailSvg />
          </div>
          <div className="check-title">
            check your email
          </div>
          <div className="subtitle">
            We sent a verification code to <br /> {RegisterEmail}
          </div>
          <div className="text" >
        
          Please check your mail to verify account
          </div>
          <button className="ver-button margin-check-button" onClick={() => window.location.href = '/verify'} >Continue</button>

        </div>
      </div>

    </div>
  );
};

export default CheckEmail;


