import React, { useState, useEffect } from 'react'
import "./ProductDetailsPage.css"
import { Container } from 'react-bootstrap';
import { ProductDetails, RelatedProducts, ProductClientReviews } from '../../Features/ProductsPage'
import { useLocation } from 'react-router-dom';
import { TrendyProduct } from '../../Features/Home/index'
import { Loading } from '../../components/index';


export const ProductDetailsPage = ({ url, apiMethod, serviceId }) => {

  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [resultData, setResultData] = useState({});
  const [contentData, setContentData] = useState({});
  // Get the pathname from the location object
  const pathname = location.pathname;
  // Split the pathname into an array of segments
  const segments = pathname.split('/').filter(segment => segment !== '');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      var apiUrl = `${url}${apiMethod}.content_by_page_name?name=product&product_name=${serviceId}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      const result = data?.result || "";
      const content = data?.result?.content || "";
      setResultData(result);
      setContentData(content);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      {loading == true ?
        (
          <Loading></Loading>
        )
        :
        <div className='product-details'>
          <Container>
            <div className="path">
              Home / {segments.map((segment, index) => (
                <span key={segment} className={index === segments.length - 1 ? 'bold' : ''}>
                  {segment} {index !== segments.length - 1 && '/ '}
                </span>
              ))}
            </div>
          </Container>
          <ProductDetails url={url} resultData={resultData} apiMethod={apiMethod} />
          <TrendyProduct contentData={contentData} url={url} />
          <ProductClientReviews url={url} apiMethod={apiMethod} resultData={resultData}></ProductClientReviews>
          <Container><div className='divider'></div></Container>
          <RelatedProducts url={url} resultData={resultData} />

        </div>}
    </div>
  )
}


export default ProductDetailsPage;