import React, { useState, useEffect } from "react";
import "./CheckoutPage.css";
import { Container, Row } from "react-bootstrap";
import { Title } from "../../components";
import { CardSummary } from '../../components/index'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
} from "@mui/material";

import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ReactComponent as PlusIcon } from "../../asset/images/svg/plus-icon.svg";
import Cookies from "js-cookie";
import MoYasar from "../../asset/images/svg/MoYasar.png";

export const CheckoutPage = ({ url, apiMethod }) => {

  const [AddressData, setAddressData] = useState([]);
  const [city, setCity] = useState('');
  const [AddressTitle, setAddressTitle] = useState('');
  const [Address1, setAddress1] = useState('');
  const [Address2, setAddress2] = useState('');
  const [BuildingNumber, setBuildingNumber] = useState('');
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [phone, setPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState("Saudi Arabia");
  const [selectedRadioAddress, setSelectedRadioAddress] = useState("");
  const [AddressType, setAddressType] = useState("Shipping");
  const [addressSelected, setAddressSelected] = useState(false);
  const [update, setUpdate] = useState(false);
  const [item_name, setItemName] = useState('');


  const handleClickOpen = () => {
    setCity("");
    setAddressTitle("");
    setAddress1("");
    setAddress2("");
    setBuildingNumber('');
    setPhone("");
    setSelectedOption('Saudi Arabia');
    setAddressType('Shipping');
    setIsChecked(false);
    setUpdate(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePhoneNumberChange = (status, value, countryData, number, id) => {
    value = { phone };
    setPhone(number);
  };


  useEffect(() => {
    fetchDataByPage();
  }, []);

  const fetchDataByPage = async () => {
    const apiURL = `${url}${apiMethod}.addresses`;
    try {
      const headers = {
        "Authorization": `token ${Cookies.get("token")}`
      };

      const response = await axios.get(apiURL, { headers });
      const data = response?.data?.result;
      setAddressData(data);

    } catch (error) {
      console.log('Error fetching products:', error);
    }
    setOpen(false);
  };


  const Add_update_address = async (method_name) => {

    const apiURL = `${url}${apiMethod}.${method_name}`;
    try {
      const headers = {
        "Authorization": `token ${Cookies.get("token")}`
      };

      var body = {
        "address_title": AddressTitle,
        "address_type": "Shipping",
        "address_line1": Address1,
        "city": city,
        "country": selectedOption,
        "address_line2": Address2,
        "state": "",
        "postal_code": "",
        "building_number": BuildingNumber,
        "email_id": "",
        "phone": phone,
        "fax": "",
        "is_primary_address": isChecked,
        "is_shipping_address": false 
      };
      
      if (method_name == "update_address") {
        body.name = item_name;
      }

      const response = await axios.post(apiURL,
        body, { headers });
      if (response.status == 200) {
        fetchDataByPage();
      }

    } catch (error) {
      console.log('Error in add address', error);
    }
  };


  const EditAddress = (address) => {
    setCity(address.city);
    setAddressTitle(address.address_title);
    setAddress1(address.address_line1);
    setAddress2(address.address_line2);
    setBuildingNumber(address.building_number);
    setSelectedOption(address.country);
    setAddressType(address.address_type);
    setPhone(address.phone);
    setIsChecked(address.is_primary_address);
    setUpdate(true);
    setOpen(true);
    setItemName(address.name);
  };


  return (
    <div className="checkout-page">
      <Container>
        <Title title={"Checkout"} />
        <Row className="">
          <div
            className="col-md-12 col-lg-8 col-12 col-sm-12"
            style={{ paddingLeft: "unset" }}
          >
            <div>
              <div className="checkout-box">
                <div className="checkout-title-div">
                  <div className="circle">1</div>
                  <span className="checkout-title">Choose a shipping address</span>
                </div>
                <div className="address-div">
                  <div className="address-title">Your address</div>
                  <div className="divider"></div>

                  {AddressData.length === 0 ? (
                    <center>  </center>

                  ) : (
                    <div >
                      {AddressData.map((item, index) => (
                        <label className="label-radio">
                          <input
                            type="radio"
                            value={item.name}
                            checked={selectedRadioAddress === item.name}
                            onChange={(e) => setSelectedRadioAddress(e.target.value)}
                          />
                          <div>
                            <span className="address-name">{item.address_title} </span>
                            <span className="address-text">
                              {" "}  {item.address_line1} , {item.address_line2} ,Building Number: {item.building_number}, {item.city}, {item.country} ({item.address_type})
                              {" "}
                            </span>
                            <span onClick={() => EditAddress(item)} style={{ cursor: 'pointer' }} className="address-edit">Edit Address</span>
                          </div>
                        </label>
                      ))}
                    </div>
                  )}

                  <div className="label-radio">
                    <span>
                      {" "}
                      <PlusIcon />
                    </span>
                    <Button className="add-address-btn"
                      onClick={() => handleClickOpen()}>
                      Add New Adress
                    </Button>
                  </div>

                  {AddressData.length !== 0 ? (
                    <div>
                      <button className="btn-selected-address"
                        onClick={() => setAddressSelected(true)}
                      >
                        Use selected Address
                      </button>
                    </div>

                  ) : null}
                </div>

                <Dialog
                  open={open}
                  onClose={() => handleClose()}
                  aria-labelledby="edit-apartment"
                >
                  <DialogTitle id="edit-apartment">
                    <div className="checkout-title-div">
                      <div className="circle">1</div>
                      <span className="checkout-title">Choose a shipping address</span>
                      <button className="close-button" style={{
                        position: "absolute",
                        right: "25px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",

                      }}
                        onClick={() => handleClose()}>
                        X
                      </button>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText className="dialog-content-text">Your new Address</DialogContentText>
                    <div className="divider"></div>

                    <div className="address-form-text">
                      Address Title
                    </div>
                    <input
                      type="text"
                      value={AddressTitle}
                      onChange={(e) => setAddressTitle(e.target.value)}
                      className="address-input"
                      placeholder="Address Title"
                    />

                    <div className="address-form-text">Country/Region</div>
                    <select
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}

                      className="address-input"
                    >
                      <option value="">Choose your country</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                    </select>

                    <div className="address-form-text">
                      City
                    </div>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="address-input"
                      placeholder="City"
                    />

                    <div
                      className="form-group margin-input"
                      style={{ position: "relative" }}
                    >
                      <div className="address-form-text">Phone Number</div>
                      <PhoneInput
                        containerClassName="intl-tel-input"
                        inputClassName="form-control  " //is-valid
                        preferredCountries={["sa",]}
                        defaultCountry="sa"
                        separateDialCode
                        onChange={handlePhoneNumberChange}
                        onSelectFlag={handlePhoneNumberChange}

                        // onSelectFlag={(e) => handlePhoneNumberChange(e.target.value)}
                      />
                    </div>
                    <div className="address-form-text">Address</div>
                    <input
                      type="text"
                      value={Address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      className="address-input"
                      placeholder="Street address or P.O. Box"
                    />

                    <input
                      style={{ marginTop: "10px" }}
                      type="text"
                      value={Address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      className="address-input"
                      placeholder="Apt, suite, unit, building, floor, etc."
                    />

                    <input
                      style={{ marginTop: "10px" }}
                      type="text"
                      value={BuildingNumber}
                      onChange={(e) => setBuildingNumber(e.target.value)}
                      className="address-input"
                      placeholder="Building number"
                    />

                    <div className="address-form-text">Address Type</div>
                    <select
                      value={AddressType}
                      onChange={(e) => setAddressType(e.target.value)}

                      className="address-input"
                    >
                      <option value="">Choose AddressType</option>
                      <option value="Shipping">Shipping</option>
                      <option value="Billing">Billing</option>

                    </select>

                    <label>
                      <input
                        style={{ marginTop: "15px", marginRight: "5px" }}
                        type="checkbox"
                        checked={isChecked}
                        onClick={() => setIsChecked(!isChecked)}

                      />
                      Make this my default address
                    </label>



                  </DialogContent>
                  <DialogActions>

                    <Button
                      className="btn-selected-address"
                      onClick={() => Add_update_address(update == true ? "update_address" : "add_new_address")}
                      color="primary"
                    >
                      Save This Address
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div className="payment-box">
                <div className="checkout-title-div ">
                  <div className={`circle ${addressSelected ? '' : 'circle-disabled'}`}>2</div>
                  <span className={`checkout-title ${addressSelected ? '' : 'checkout-title-disabled'}`}>Payment method</span>
                </div>
                <div className={`payment-div ${addressSelected ? '' : 'payment-div-disable '}`}>
                  <div className="address-div payment-text">Use MOYASAR platform to pay by press on this Button  </div>
                  <Button className="btn-payment">Pay with <img src={MoYasar} alt="MoYasar" className="" /></Button>
                </div>

              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-4 col-12 col-sm-12"
            style={{ paddingLeft: "unset" }}
          >
            <CardSummary fromCheckOut={true}></CardSummary>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CheckoutPage;