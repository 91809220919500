import React , {useState} from 'react';
import "./SpecialOffer.css"
import { Timer } from '../../../../components';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../../../components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';

const SpecialOffer = ({ contentData,itemList, url }) => {
  const products = itemList || [];
  const [timerComplete, setTimerComplete] = useState(false); // State variable to track timer completion
  const handleTimerComplete = () => {
    setTimerComplete(true); // Update state variable when the timer is complete
  };
  if (!timerComplete)
    return (
      <Container className="offers">
        <Row>
          <Col lg={5} md={6} className="offer-content">

              <div className="offer-para" data-aos="fade-right" data-aos-duration="1500">
                {/* <h1>Special Offers! Up to <span>50%</span> OFF</h1>
                <p>Hurry Up! Offer End In:</p> */}
                <p  dangerouslySetInnerHTML={{ __html: contentData.special_offer_title }}/>
                <p>{contentData.special_offer_description}</p> 

              </div>
              <Timer time={contentData.special_offer_time}  onComplete={handleTimerComplete}/> 
             
          </Col>
          
          <Col lg={7} md={6}>
            <Splide className="offer-slider"
              options= {{
                type   : 'loop',
                perPage: 3,
                perMove: 1,
                pagination: false,
                breakpoints: {
                  1200: {
                    perPage: 2,
                  },
                  992: {
                    perPage: 1,
                    gap: "0",
                    focus: 'center',
                  },
                  768: {
                    perPage: 2,
                    focus: 'none'
                  },
                  576: {
                    perPage: 1,
                    gap: "0",
                    focus: 'center',
                  }
                }
              }}
            >

      {products.map((product, index) => (
                    <SplideSlide>
                      <Card 
                        title={product.item_group}
                        para={product.web_item_name}
                        image={
                          product.image
                          ? url + product.image
                          : require("../../../../asset/images/Products/product1.png")
                      }
                        pricebefore={product.before_discount_price_list_rate}
                        priceafter={product.discounted_price_list_rate}
                        sale={"-25%"}
                      />
                    </SplideSlide>
                  ))}
            </Splide>
          </Col>
        </Row>
      </Container>
 
    );
    else{
      return <Container></Container>
    }
  };
  
  export default SpecialOffer;