import React , { useState } from 'react'
import "./Card.css"


const CardSummary = ({subTotal,taxes, currency,grandTotal,fromCheckOut=false}) => {
  
  const [inputValue, setInputValue] = useState('');
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



   return (
     <div className="card-summary">
       {fromCheckOut== true ?   <div className="billing-title">Order Summary</div>:
       
       <div className="billing-title">Billing Summary</div>
       }
      
      
       <div>
         <div className="card-summary-details">
           <span className="">Subtotal</span>
           <span className="summary-price">{subTotal} {currency}</span>  
         </div>
         <div className="card-summary-details">
           <span className="">Discount</span>
           <span className="summary-price">-150 {currency}</span>  
         </div>
         <div className="card-summary-details">
           <span className="">Tax</span>
           <span className="summary-price">{taxes} {currency}</span>  
         </div>
       </div>

       <div className="divider"></div>
       <div>
           <span className="billing-title">Subtotal Total </span>
           <span className="summary-price">{grandTotal} {currency}</span>
         </div>
       {
        fromCheckOut== false ?  <div>

         <div className="order-comment-label">Order Comment</div>
         <input
           type="text"
           value={inputValue}
           onChange={handleChange}
           className="order-coment"
         />
         <label className="checkbox-label">
           <input
             type="checkbox"
             checked={isChecked}
             onChange={handleCheckboxChange}
           />
           Please check to acknowledge our Privacy & Terms Policy
         </label>
         <div>
           <button className='btn-ckeckout' onClick={() => window.location.href = '/checkout'} >Proceed to checkout</button> 
         </div>
        </div>
        : null
       }
    
      
 


     </div>
   );
}

export default CardSummary