import React, { useState, useEffect } from "react";
import "./Filters.css";
import { PriceRange, CategoriesCheckBox } from "../../../../components";

const Filters = ({
  url,
  onSearch,
  onPriceFilter,
  CategoriesFilter,
  BrandsFilter,
  onCategoryChange,
  onCategoryChange1,
  onChangeBrand,
  isCategory,
  categoryParams=[]
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  const handlePriceFilter = (min_price, max_price) => {
    onPriceFilter(min_price, max_price);
  };

  const [showAllBrands, setShowAllBrands] = useState(false);
  const brands = BrandsFilter || [];
  const visibleBrands = showAllBrands ? brands : brands.slice(0, 4);

  const toggleShowAllBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  useEffect(() => {
  }, [categoryParams]);
  
  return (
    <div className="filters">
      <div className="filter">
        <div className="filter-title">FILTER BY </div>
        <div className="divider"></div>
        <div className="filter-sub-title">Search </div>

        <div className="search-field">
          <input
            type="text"
            placeholder="Search"
            className="filter-search"
            value={searchTerm}
            onChange={handleChange}
            //  onSubmit={handleChange}
          />
        </div>

          <div className="brand-filter">
            <div className="filter-sub-title">Brand </div>

            {visibleBrands.map((brand, index) => (
              <label className="check-items" key={index}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  value={brand.name}
                  onChange={(event) => onChangeBrand(event)}
                  onClick={(event) => onChangeBrand(event)}
                />{" "}
                {brand.name}
              </label>
            ))}
            {!showAllBrands && brands.length > 4 && (
              <div className="more-brands" onClick={toggleShowAllBrands}>
                Load More
              </div>
            )}
          </div>
        {!isCategory && <div className="divider"></div>}

        {!isCategory && (
          <PriceRange
            minPrice={0}
            maxPrice={10000}
            handlePrice={handlePriceFilter}
          />
        )}
        {!isCategory && <div className="divider"></div>}
        {!isCategory && (
          <div className="categories">
            <div className="filter-sub-title">Categories </div>

            {CategoriesFilter.map((category, index) => (
              <CategoriesCheckBox
                title={category.name}
                subcategories={category.children || []}
                onCategoryChange={onCategoryChange}
                onCategoryChange1={onCategoryChange1}
                selectedCategoryFromCP={categoryParams }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
