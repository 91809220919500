import React from 'react';
import "./ClientReviews.css"
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { Title, ReviewClient } from "../../../../components";


const ClientReviews = ({ contentData, url }) => {

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
  };

  return (
    <Container>
        <Title 
          title={contentData.client_reviews_title?? "Client Reviews"}
          para={contentData.client_reviews_description??"Lorem ipsum dolor sit amet, consectetur adipisicing elit."}
        />
        <Slider {...settings} className="reviews mt-5">
            <ReviewClient 
                name={"Sami Abdul"}
                para={" Lorem ipsum dolor sit amet, consectetur adipiscing elit duis nec fringilla nibh pellentesque finibus Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                image={require("../../../../asset/images/Home/profile-1.png")}
            />
            <ReviewClient 
                name={"Rania Abdul"}
                para={" Lorem ipsum dolor sit amet, consectetur adipiscing elit duis nec fringilla nibh pellentesque finibus Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                image={require("../../../../asset/images/Home/profile.png")}
            />
            <ReviewClient 
                name={"Sami Abdul"}
                para={" Lorem ipsum dolor sit amet, consectetur adipiscing elit duis nec fringilla nibh pellentesque finibus Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                image={require("../../../../asset/images/Home/profile-1.png")}
            />
            <ReviewClient 
                name={"Rania Abdul"}
                para={" Lorem ipsum dolor sit amet, consectetur adipiscing elit duis nec fringilla nibh pellentesque finibus Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                image={require("../../../../asset/images/Home/profile.png")}
            />
      </Slider>
    </Container>
  );
};

export default ClientReviews;